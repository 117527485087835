


























import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class AdminPanel extends Vue {
  get pages() {
    return [
      { name: this.$t("admin.users").toString(), path: "/admin/users" },
      { name: this.$t("admin.tuts").toString(), path: "/admin/videos" },
      { name: this.$t("admin.slides").toString(), path: "/admin/slides" },
    ];
  }

  get isOnMain() {
    const { path } = this.$route;
    return path === "/admin";
  }
}
